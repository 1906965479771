import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Form from "../components/Form"
import withLocation from "../components/withLocation"
import "fontsource-domine"
import "fontsource-muli"
import "./layout.css"
import data from "./data.json"

const IndexPage = ({ search }) => {
  const { param, id, value } = search
  let modifiedName
  if (data[id]) {
    modifiedName = data[id].name
  }
  if (modifiedName) {
    modifiedName = modifiedName.toUpperCase()
  } else {
    modifiedName = "FIERCE ISLAND REVIEWS"
  }

  return (
    <>
      <SEO />
      <div className="main">
        {(() => {
          switch (param) {
            case "legacy":
              return (
                <>
                  <div className="wrapper">
                    <h1>Tell Us About Your Experience</h1>
                    <h2>How would you rate our services and/or products?</h2>
                    <section className="button-container">
                      <Link
                        className="button"
                        to={`?param=false&id=${id}&value=true`}
                      >
                        GREAT!
                      </Link>
                      <Link
                        className="button"
                        to={`?param=false&id=${id}&value=false`}
                      >
                        NOT SO GREAT
                      </Link>
                    </section>
                  </div>
                </>
              )
            case "true":
              return (
                <>
                  {/* {value === "true" && ( */}
                  <div className="wrapper">
                    <h1>Leave a Review</h1>
                    <h2>We love knowing how we are doing.</h2>
                    <h3>
                      Want to help us out? <br />
                      Please add your review to Google reviews by clicking the
                      button below.
                    </h3>
                    <section className="button-container-2">
                      <Link className="button" to={`${data[id].link}`}>
                        Leave A Review
                      </Link>
                    </section>
                  </div>
                  {/* )} */}
                  {/* {value === "false" && (
                    <div className="wrapper">
                      <h1>We are so sorry to hear that.</h1>
                      <h2 className="alternative">
                        What can we do to make this right?
                      </h2>
                      <h3 className="alternative">
                        Please fill out the form, along with a brief description
                        of your experience.
                      </h3>
                      <div className="form-wrapper">
                        <Form
                          client={{
                            name: data[id].name,
                            email: data[id].email,
                          }}
                        />
                      </div>
                    </div>
                  )} */}
                </>
              )
            default:
              return (
                <div className="wrapper">
                  <h1>Oops...something went wrong.</h1>
                  <h2>Looks like this link has expired.</h2>
                </div>
              )
          }
        })()}
      </div>
      <footer>
        © {new Date().getFullYear()}
        {` `}
        {modifiedName}
      </footer>
    </>
  )
}

export default withLocation(IndexPage)
